import React, { useState, useEffect } from "react";
import style from "./Popup.module.css";
import { Button } from "../Button/Button";

const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // Show the popup after 10 seconds
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 4000);

        return () => clearTimeout(timer); // Cleanup timer
    }, []);

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            {showPopup && (
                <div className={style.popupOverlay}>
                    <div className={style.popupContent}>
                        <button className={style.popupClose} onClick={closePopup}>
                            &times;
                        </button>
                        <h1 className={style.title}>🍷 Този февруари спечели винена дегустация за целия отбор 🍷</h1>
                        <p className={style.text}>
                            Нашите приятели от Vineria – bar and shop ще ви потопят в света на виното с 5 висококачествени български вина, поднесени с колбаси и сирена 🧀
                        </p>
                        <h3 className={style.subtitle}>Как да участваш?</h3>
                        <ol className={style.popupList}>
                            <li className={style.text}>Купи градска игра от 01.02. (08:00 ч.) до 20.02. (19:00 ч.)</li>
                            <li className={style.text}>Автоматично влизаш в томбола за дегустация за целия отбор*</li>
                            <li className={style.text}>На 20.02. вечерта ще изтеглим победителя, като ще го уведомим лично и ще го обявим в социалните ни мрежи</li>
                        </ol>
                        <p className={style.text}>
                            *Дегустацията е за отбор до максимум 5 човека. Повече за условията на{" "}
                            <a href='https://igritenagrada.com/terms'>https://igritenagrada.com/terms</a>
                        </p>
                        <div className={style.btnWrapper} onClick={closePopup} width='inherit'>
                            <Button version='version-11' navigate='/catalogue' text='Купи сега'></Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Popup;
